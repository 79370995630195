import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultInfluencerIcon, MailIcon, TrashIcon } from '../../assets';
import { PERMISSIONS } from '../../config/constants';
import { Confirm } from '../../context/confirm/confirm';
import {
  DELETE_USER,
  RESEND_USER_INVITE,
  UPDATE_PROFILE,
} from '../../graphql/user/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import { formatCustomDate } from '../../utils/numberFormatter';
import { Button } from '../common/Button';
import { Toggle } from '../inputs/Toggle';

export default function TeamMember({
  user,
  refetch,
}: {
  user?: any;
  refetch?: any;
}) {
  const [deleteUser, { loading }] = useMutation(DELETE_USER);
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [resendInvite, { loading: loadingResend }] =
    useMutation(RESEND_USER_INVITE);

  const { notifySaveSuccess, notifySaveError, notifyResendSuccess } =
    useNotification();
  const toggleAccess = () => {
    updateProfile({
      variables: {
        userId: user.id,
        hasAccess: !user.blocked,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const deleteUserDB = () => {
    deleteUser({
      variables: {
        userId: user.id,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'DeleteUser',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const { t } = useTranslation('common');
  const toggleRef = useRef<{
    toggle: (value: boolean) => void;
    setCheckedState: (state: boolean) => void;
  }>(null);

  const handleToggleClick = (value: boolean) => {
    if (toggleRef.current) {
      toggleRef.current.toggle(value); // Llama a la función toggle
    }
  };

  const [open, setOpen] = useState(false);
  const [accessOpen, setAccessOpen] = useState(false);
  const lastAccess = user?.last_login
    ? formatCustomDate(user?.last_login, i18next.language) // Passing user's language
    : t('global.never');

  const { isInfluentia, checkPermission } = usePermissions();

  const onResendClick = () => {
    resendInvite({
      variables: {
        user_id: user?.id,
      },
      onCompleted: () => {
        notifyResendSuccess();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div id="teamMember">
      <Confirm
        open={open}
        onConfirm={() => deleteUserDB()}
        id="deleteTeamMemberDialog"
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <Confirm
        open={accessOpen}
        onConfirm={() => toggleAccess()}
        id="accessPortal"
        setOpen={setAccessOpen}
        onCancel={() => {
          handleToggleClick(!user.blocked);
        }}
        type="warning"
        title={
          user.blocked
            ? t('global.grantAccessTitle')
            : t('global.removeAccessTitle')
        }
        subtitle={
          user.blocked
            ? t('global.grantAccessSubTitle')
            : t('global.removeAccessSubTitle')
        }
        confirmText={
          user.blocked
            ? t('global.grantAccessButton')
            : t('global.removeAccessButon')
        }
        cancelText={t('global.cancel')}
      />
      <div className="bg-dark-blue hover:bg-dark-blue-popups rounded-10 w-max md:w-auto">
        <div className="py-1 px-2.5 flex flex-row justify-between lg:gap-20">
          <div className="flex flex-row gap-5 items-center sm:min-w-[200px]">
            <div>
              {user && !user.profile_picture && (
                <DefaultInfluencerIcon className="size-[50px]" />
              )}
              {user && user.profile_picture && (
                <img
                  src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
                  className={`rounded-full size-[50px] object-cover `}
                />
              )}
            </div>
            <span className="font-bold min-w-[150px]">{user.name}</span>
          </div>
          <div className="flex flex-row gap-1 items-center min-w-[270px]">
            <MailIcon className="size-[14px]" />
            <span className="font-medium">{user.email}</span>
          </div>
          {/* <div className="flex flex-row items-center">
            <span className="font-medium">{user.title}</span>
          </div> */}
          <div
            className={`flex flex-row items-center min-w-[250px] max-w-full ${!isInfluentia() && 'hidden'}`}
          >
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-row gap-3 items-center justify-center">
                <span className="font-inter text-[14px]">
                  {t('profile.accessPortal')}
                </span>
                <Toggle
                  ref={toggleRef}
                  value={!user.blocked}
                  minWidth=""
                  backgroundColor="bg-influentia-black"
                  onChange={() => {
                    setAccessOpen(true);
                  }}
                />
              </div>
              <div className="flex flex-row gap-1 items-center w-full justify-center">
                <span className="font-medium text-[12px]">
                  {t('profile.lastAcess')}:
                </span>
                <span className="font-medium text-[12px] text-influentia-light-blue">
                  {lastAccess}
                </span>
              </div>
            </div>
          </div>
          {checkPermission(PERMISSIONS.SEND_INVITE) && (
            <div className="flex items-center">
              <div className="">
                <Button
                  style="light-blue"
                  text={t('influencers.resendInvite')}
                  onClick={onResendClick}
                  loading={loadingResend}
                />
              </div>
            </div>
          )}
          <div className="flex items-center">
            <button
              disabled={loading}
              id="deleteTeamMember"
              onClick={() => setOpen(true)}
              className="text-white "
            >
              <TrashIcon className="size-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
