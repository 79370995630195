import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  CameraIcon,
  DefaultInfluencerIcon,
  MailIcon,
  TrashIcon,
} from '../../assets';
import { PERMISSIONS } from '../../config/constants';
import { ROUTES } from '../../config/routes/config';
import { Confirm } from '../../context/confirm/confirm';
import {
  DELETE_BRAND,
  DELETE_BRAND_GROUP,
  DELETE_BRAND_GROUP_USER,
  UPDATE_BRAND,
  UPDATE_BRAND_PROFILE_PICTURE,
} from '../../graphql/brands/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import type { BrandGroup, SingleBrand, SingleUser } from '../../types';
import { Tab } from '../campaigns/SingleCampaignTabs';
import { Button } from '../common/Button';
import ExpandableListElement from '../common/ExpandableListElement';
import FileUploaderInvisible from '../common/FileUploaderInvisible';
import { LateralProfileBrandMenu } from '../common/LateralProfileBrandMenu';
import { TextInput } from '../inputs/TextInput';
import { BrandGroupPopUp } from '../popups/BrandGroupPopup';
import { CreateBrandUser } from '../popups/CreateBrandUser';
import TeamMember from './TeamMember';

type BrandFormProps = {
  info?: SingleBrand;
  refetch: () => void;
};

export const BrandForm = ({ info, refetch }: BrandFormProps) => {
  const { t } = useTranslation('common');
  const [openGroup, setOpenGroup] = useState<null | BrandGroup | true>(null);
  const [brand, setBrand] = useState<any>(info);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { isInfluentia, checkPermission } = usePermissions();

  const { notifySaveSuccess, notifySaveError } = useNotification();

  const [deleteBrandDB, { loading: loadingDelete }] = useMutation(DELETE_BRAND);

  const deleteBrand = () => {
    deleteBrandDB({
      variables: {
        brandId: brand.id,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'DeleteBrand',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        navigate(ROUTES.BRANDS);
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const [updateBrand, { loading }] = useMutation(UPDATE_BRAND);
  const onSubmit = (data: any) => {
    updateBrand({
      variables: {
        brandId: brand.id,
        cif: data.cif,
        address: data.address,
        paymentTerms: data.paymentSteps,
        companyName: data.companyName,
        name: data.name,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  useEffect(() => {
    setBrand(info);
  }, [info]);

  useEffect(() => {
    if (brand) {
      setValue('name', brand.name);
      setValue('cif', brand.cif);
      setValue('address', brand.address);
      setValue('paymentSteps', brand.paymentTerms);
      setValue('companyName', brand.companyName);
    }
  }, [brand]);

  const [updateProfilePicture] = useMutation(UPDATE_BRAND_PROFILE_PICTURE);

  const uploadFile = (file?: File) => {
    updateProfilePicture({
      variables: {
        file,
        brandId: brand.id,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'UpdateBrandProfilePicture',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  // Delete group
  const [deleteBrandGroup] = useMutation<any>(DELETE_BRAND_GROUP);

  const onDeleteBrandGroup = (id: string) => {
    deleteBrandGroup({
      variables: {
        id,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  // Delete single user from group
  const [deleteBrandGroupUser] = useMutation<any>(DELETE_BRAND_GROUP_USER);

  const onDeleteBrandGroupUser = (group_id: string, user_id: string) => {
    deleteBrandGroupUser({
      variables: {
        group_id,
        user_id,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      {/* <Breadcrumbs
        icon={<UserIcon />}
        breadcrumbs={[
          {
            text: t('brand.title'),
          },
        ]}
      /> */}
      {brand && (
        <>
          {isInfluentia() && (
            <div className="lg:hidden flex flex-row gap-5">
              <div className="flex flex-row gap-x-2 overflow-auto customScroll pb-1 w-full">
                <Tab
                  to={ROUTES.SINGLE_BRAND.replace(':id', brand.id)}
                  id="personalInfoTab"
                  text={t('profile.menu.title1')}
                />
                <Tab
                  to={ROUTES.SINGLE_BRAND_CAMPAIGNS.replace(':id', brand.id)}
                  id="campaignsTab"
                  text={t('profile.menu.title2')}
                />
              </div>
            </div>
          )}

          <div className="flex flex-col lg:gap-x-5 lg:gap-y-0 gap-y-5">
            {openGroup && (
              <BrandGroupPopUp
                close={() => setOpenGroup(null)}
                refetch={refetch}
                id={openGroup !== true ? openGroup.id : undefined}
                name={openGroup !== true ? openGroup.name : undefined}
                users={openGroup !== true ? openGroup.users : undefined}
                brand_id={brand.id}
              />
            )}
            <Confirm
              open={openConfirm}
              onConfirm={() => deleteBrand()}
              id="deleteBrandDialog"
              setOpen={setOpenConfirm}
              type="delete"
              title={t('global.deleteTitle')}
              subtitle={t('global.deleteSubtitle')}
              confirmText={t('global.delete')}
              cancelText={t('global.cancel')}
            />
            <>
              <div className="flex flex-row lg:gap-12 h-full">
                {isInfluentia() && (
                  <>
                    <LateralProfileBrandMenu brand={brand} />
                  </>
                )}
                <div className="flex flex-col w-full">
                  <div className="flex lg:flex-row flex-col w-full">
                    <div className="flex flex-col gap-12 w-full">
                      <div className="flex flex-col">
                        <span className="text-[16px] uppercase mb-2.5">
                          {t('global.profilePicture')}
                        </span>
                        <div className="w-full">
                          <div className="flex flex-row gap-5 items-center">
                            <div className="group relative size-[96px] items-center flex justify-center">
                              {brand && !brand.profilePicture && (
                                <DefaultInfluencerIcon className="size-[96px] group-hover:opacity-50" />
                              )}
                              <CameraIcon className="absolute group-hover:block hidden z-[5]" />
                              {brand && brand.profilePicture && (
                                <img
                                  src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${brand.profilePicture}`}
                                  className={`rounded-full size-[96px] object-cover group-hover:opacity-50`}
                                />
                              )}
                              <FileUploaderInvisible submitFile={uploadFile} />
                            </div>
                            <span className="text-2xl font-bold">
                              {brand.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="flex lg:flex-row flex-col gap-12 size-full">
                          <div className="flex flex-col w-full">
                            <span className="text-[16px] uppercase">
                              <p>{t('singleBrand.fiscalInfo')}</p>
                            </span>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="px-2.5 w-full">
                                <TextInput
                                  type="text"
                                  register={register}
                                  registerName="name"
                                  label={t('singleBrand.name')}
                                  placeholder={t('singleBrand.name')}
                                  selectedValue={brand.name}
                                />
                                <TextInput
                                  type="text"
                                  register={register}
                                  registerName="companyName"
                                  label={t('brands.createRazon')}
                                  placeholder={t('brands.createRazon')}
                                  selectedValue={brand.companyName}
                                />
                                <TextInput
                                  type="text"
                                  register={register}
                                  registerName="cif"
                                  selectedValue={brand.email}
                                  label={t('singleBrand.CIFPlaceholder')}
                                  placeholder={t('singleBrand.CIFPlaceholder')}
                                />
                                <TextInput
                                  type="text"
                                  registerName="address"
                                  register={register}
                                  maxLength={999}
                                  selectedValue={brand.address}
                                  label={t('singleBrand.address')}
                                  placeholder={t(
                                    'singleBrand.addressPlaceholder',
                                  )}
                                />
                                <div className="pt-2 justify-end flex">
                                  <Button
                                    text={t('global.save')}
                                    loading={loading}
                                    type="submit"
                                    style="light-blue"
                                    customClassnames="!px-14 flex text-center items-center justify-center"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`flex flex-col lg:ml-12 w-full`}>
                      <span className="text-[16px] uppercase">
                        {t('singleBrand.paymentDetails')}
                      </span>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-2.5 md:min-w-[400px]">
                          <TextInput
                            type="number"
                            register={register}
                            registerName="paymentSteps"
                            label={t('singleBrand.payment')}
                            placeholder={t('singleBrand.paymentPlaceholder')}
                            errors={errors.paymentSteps}
                          />
                          <div className="pt-2 justify-end flex">
                            <Button
                              text={t('global.save')}
                              loading={loading}
                              type="submit"
                              style="light-blue"
                              customClassnames="!px-14 flex text-center items-center justify-center"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div>
                    {brand.users && (
                      <div className="mt-10">
                        <span className="uppercase">
                          {t('singleBrand.team')}
                        </span>
                        <div className="mt-2">
                          <div
                            id="teamMembers"
                            className="flex flex-col gap-2 w-full md:overflow-auto overflow-scroll"
                          >
                            {brand.users.map((u: any) => (
                              <TeamMember
                                key={u.id}
                                user={u}
                                refetch={refetch}
                              />
                            ))}
                          </div>
                          <div className="flex items-center justify-center mt-2">
                            <button
                              id="addTeamMember"
                              onClick={() => setOpen(true)}
                            >
                              <span className="font-medium text-xs underline">
                                {t('brands.addUser')}
                              </span>
                            </button>
                          </div>
                        </div>
                        <CreateBrandUser
                          setOpen={setOpen}
                          open={open}
                          brandId={brand.id}
                          refetch={refetch}
                        />
                      </div>
                    )}
                  </div>
                  {isInfluentia() && (
                    <div className="my-10">
                      <span className="uppercase">
                        {t('singleBrand.groups')}
                      </span>
                      <div className="flex flex-col gap-2 w-full md:overflow-auto overflow-scroll">
                        {brand.groups?.map((bg: BrandGroup) => (
                          <ExpandableListElement
                            editFunction={() => setOpenGroup(bg)}
                            title={bg.name}
                            noSubrowsText={t('singleBrand.noUsersGroup')}
                            deleteFunction={() => onDeleteBrandGroup(bg.id)}
                            SubrowComponent={({
                              name,
                              email,
                              profile_picture,
                              id,
                            }: SingleUser) => {
                              return (
                                <>
                                  <div className="flex flex-row gap-5 items-center sm:min-w-[200px]">
                                    <div>
                                      {!profile_picture && (
                                        <DefaultInfluencerIcon className="size-[50px]" />
                                      )}
                                      {profile_picture && (
                                        <img
                                          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${profile_picture}`}
                                          className={`rounded-full size-[50px] object-cover `}
                                        />
                                      )}
                                    </div>
                                    <span className="font-bold min-w-[150px]">
                                      {name}
                                    </span>
                                  </div>
                                  <div className="flex flex-row gap-1 items-center min-w-[270px]">
                                    <MailIcon className="size-[14px]" />
                                    <span className="font-medium">{email}</span>
                                  </div>
                                  <div className="flex items-center">
                                    <button
                                      disabled={loading}
                                      id="deleteTeamMember"
                                      onClick={() =>
                                        onDeleteBrandGroupUser(bg.id, id)
                                      }
                                      className="text-white "
                                    >
                                      <TrashIcon className="size-[24px]" />
                                    </button>
                                  </div>
                                </>
                              );
                            }}
                            subrowElements={bg.users.map((u) =>
                              brand.users.find((u2: any) => u2.id === u),
                            )}
                          />
                        ))}
                        {!brand.groups ||
                          (brand.groups.length === 0 && (
                            <div className="bg-dark-blue rounded-10 w-max md:w-auto p-2 italic text-sm text-white text-center">
                              <p>{t('singleBrand.noGroup')}</p>
                            </div>
                          ))}
                      </div>
                      <div className="flex items-center justify-center mt-2">
                        <button
                          id="addGroup"
                          onClick={() => setOpenGroup(true)}
                        >
                          <span className="font-medium text-xs underline">
                            {t('brands.addGroup')}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    className={`w-full mt-5 ${!checkPermission(PERMISSIONS.DELETE_BRAND) && '!hidden'}`}
                  >
                    <div className="flex items-end justify-end">
                      <Button
                        style="black"
                        id="deleteBrand"
                        customClassnames="flex w-full shrink-0 items-center justify-center !bg-red-status py-1.5 px-5 rounded-[4px]"
                        iconLeft={<TrashIcon />}
                        loading={loadingDelete}
                        onClick={() => setOpenConfirm(true)}
                        text={t('profile.deleteAccount')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};
