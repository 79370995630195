import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';

export const useNotification = () => {
  const { t } = useTranslation('common');

  const notifySaveSuccess = () => {
    toast.success(t('notifications.save-ok'), {
      toastId: 'notifySaveOk',
    });
  };

  const notifySaveError = () => {
    toast.error(t('notifications.save-error'), {
      toastId: 'notifySaveError',
    });
  };

  const notifyUpdateError = () =>
    toast.error(t('notifications.update-error'), {
      toastId: 'notifyUpdateError',
    });

  const notifyUpdateOk = () =>
    toast.success(t('notifications.update-ok'), {
      toastId: 'notifyUpdateOk',
    });

  const notifyDeleteOk = () =>
    toast.success(t('notifications.delete-ok'), {
      toastId: 'notifyDeleteOk',
    });

  const notifyDeleteError = () =>
    toast.error(t('notifications.delete-error'), {
      toastId: 'notifyDeleteError',
    });

  const notifyNoSelection = () =>
    toast.warn(t('notifications.no-selection'), {
      toastId: 'notifyNoSelection',
    });

  const notifySelectedFile = () =>
    toast.success(t('notifications.selectedFile'), {
      toastId: 'notifySelectedFile',
    });

  const notifyCopy = () =>
    toast.success(t('notifications.copy'), {
      toastId: 'notifyCopy',
    });

  const notifyFileError = () =>
    toast.error(t('notifications.fileError'), {
      toastId: 'notifyFileError',
    });

  const notifyFileSizeError = (size: number) =>
    toast.error(t('notifications.fileSizeError', { size }), {
      toastId: 'notifyFileSizeError',
    });

  const notifyResendSuccess = () => {
    toast.success(t('notifications.resend-ok'), {
      toastId: 'notifySaveOk',
    });
  };

  const notifyError = (message: string) =>
    toast.error(message, {
      toastId: 'notifyError',
    });

  const notifyWarning = (message: string) =>
    toast.warning(message, {
      toastId: 'notifyWarning',
    });

  return {
    notifyWarning,
    notifyCopy,
    notifyError,
    notifyFileSizeError,
    notifySelectedFile,
    notifyFileError,
    notifySaveSuccess,
    notifySaveError,
    notifyUpdateError,
    notifyUpdateOk,
    notifyDeleteOk,
    notifyDeleteError,
    notifyNoSelection,
    notifyResendSuccess,
  };
};
