import { useMutation } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UsersGroupsIcon } from '../../assets';
import {
  CREATE_BRAND_GROUP,
  UPDATE_BRAND_GROUP,
} from '../../graphql/brands/mutation';
import type { ResCreateBrandGroupType } from '../../types';
import { Button } from '../common/Button';
import { TextInput } from '../inputs/TextInput';
import { UserDropdownList } from '../inputs/UserDropdownList';
import { PopupLayout } from './PopupLayout';

type TBrandGroupPopUp = {
  id?: string;
  name: string;
  brand_id: string;
  users: { value: string }[];
};

export function BrandGroupPopUp({
  close,
  refetch,
  brand_id,
  name,
  users,
  id,
}: {
  close: () => void;
  refetch: any;
  id?: string;
  name?: string;
  brand_id?: string;
  users?: string[];
}) {
  const methods = useForm<TBrandGroupPopUp>({
    defaultValues: {
      brand_id,
      name,
      users: users?.map((u) => ({ value: u })),
      id,
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const { t } = useTranslation('common');
  const [createBrandGroup, { loading: loadingCreate }] =
    useMutation<ResCreateBrandGroupType>(CREATE_BRAND_GROUP);
  const [updateBrandGroup, { loading: loadingUpdate }] =
    useMutation<ResCreateBrandGroupType>(UPDATE_BRAND_GROUP);

  const onCompleted = () => {
    refetch();
    close();
  };

  const onSubmit = (data: TBrandGroupPopUp) => {
    if (id) {
      updateBrandGroup({
        variables: {
          id: data.id,
          name: data.name,
          users: data.users.map((u) => u.value).filter((u) => u),
        },
        onCompleted,
      });
    } else {
      createBrandGroup({
        variables: {
          brand_id,
          name: data.name,
          users: data.users.map((u) => u.value).filter((u) => u),
        },
        onCompleted,
      });
    }
  };

  const stopPropagationAndSubmit = (event: any) => {
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };
  return (
    <>
      <PopupLayout
        title={t(`brands.groupPopup.${id ? 'editTitle' : 'createTitle'}`)}
        closeModal={close}
        icon={<UsersGroupsIcon className="text-white fill-current" />}
      >
        <form
          onSubmit={stopPropagationAndSubmit}
          className="flex flex-col gap-y-5 mt-5"
        >
          <FormProvider {...methods}>
            <div className="flex flex-row gap-x-8 w-full">
              <TextInput
                register={register}
                registerName="name"
                type="text"
                label={t('brands.groupPopup.name')}
                placeholder={t('brands.groupPopup.name')}
                validation={{ required: t('brands.groupPopup.nameRequired') }}
                errors={errors.name}
              />
            </div>
            <div>
              <UserDropdownList
                type="brand"
                accessName="users"
                label={t('brands.groupPopup.addUsers')}
                accessKey="value"
                addText={t('brands.groupPopup.addUser')}
                placeholderSelects={t('brands.groupPopup.addUser')}
                control={control}
                brand_id={brand_id}
              />
            </div>
            <div className="flex justify-end">
              <div className="w-[150px]">
                <Button
                  style="light-blue"
                  id="createBrandPopup"
                  text={t('global.save')}
                  customClassnames="w-full justify-center"
                  type="submit"
                  loading={loadingCreate || loadingUpdate}
                />
              </div>
            </div>
          </FormProvider>
        </form>
      </PopupLayout>
    </>
  );
}
