import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyCashIcon } from '../../../assets';
import type { ActionsPrice } from '../../../types';
import type { SimpleAction } from '../../../types/action';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';

type CampaignPriceProps = {
  price: number;
  currency?: string;
  variableType?: string;
  variableAmount?: number;
  actions?: SimpleAction[] | ActionsPrice[];
  variable?: boolean;
};

export const CampaignPrice = ({
  price,
  currency,
  variableType,
  variableAmount,
  actions,
  variable,
}: CampaignPriceProps) => {
  const { t } = useTranslation('common');
  const formatActions = (acts?: SimpleAction[] | ActionsPrice[]) => {
    if (!acts) return [];
    const out: SimpleAction[] | ActionsPrice[] = [];
    acts.forEach((a) => {
      const i = out.findIndex((a2) => a2.id === a.id);
      if (i === -1) out.push({ ...a } as any);
    });
    return out;
  };
  const actionsPrice = useMemo(
    () => formatActions(actions),
    [formatActions, actions],
  );

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <MoneyCashIcon className="size-[16px]" />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('singleCampaign.campaignPrice')}
        </span>
        <span className="font-medium text-sm text-white">
          {formatNumberMoney(price, undefined, currency)}
          {variableType && variableType !== 'No' && (
            <>
              {' + '}
              <span>{t(`varibale_price_type.${variableType}`)}</span>
              {variableType !== 'Per Action' &&
                variableAmount !== undefined && (
                  <span>
                    {' '}
                    {formatNumberMoney(variableAmount, undefined, currency)}
                  </span>
                )}
            </>
          )}
          {variable && <span> + {t('singleCampaign.variablePricing')}</span>}
        </span>
        {variableType === 'Per Action' && actionsPrice && (
          <div className="flex flex-col gap-y-1 font-medium text-sm text-white">
            {actionsPrice.map((a) => (
              <div
                key={a.id + Math.random()}
                className="flex flex-row gap-x-1 items-center"
              >
                <SocialNetworkImage socialNetwork={a.platform} />
                {a.platform} {t(`actionType.${a.type}`)}
                {' - '}
                {t(`varibale_price_type.${a.variable_type}`)}{' '}
                {formatNumberMoney(
                  a.variable_amount,
                  undefined,
                  a.variable_currency,
                )}
              </div>
            ))}
            2
          </div>
        )}
      </div>
    </div>
  );
};
