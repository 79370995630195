import 'react-datepicker/dist/react-datepicker.css';

import { es } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { CalendarIcon, TooltipIcon } from '../../assets';
import { Error } from '../common/Error';

registerLocale('es', es);

type DatePickerInputProps = {
  label?: string;
  placeholder?: string;
  control?: any;
  validation?: any;
  errors?: any;
  name: string;
  withModal?: boolean;
  onChange?: (date: string | null) => void;
  type?: string;
  height?: 'h-[41px]' | 'h-[25px]' | 'h-[37px]';
  limitDate?: Date;
  monthYearPicker?: boolean;
  isClearable?: boolean;
  hasIcon?: boolean;
  tooltipText?: string;
  id?: string;
  fullSpace?: boolean;
};

export const DatePickerInput = ({
  label,
  errors,
  placeholder,
  control,
  validation,
  name,
  withModal,
  id,
  onChange,
  type = 'default',
  height = 'h-[41px]',
  limitDate,
  monthYearPicker = false,
  isClearable = false,
  hasIcon = true,
  tooltipText,
  fullSpace = false,
}: DatePickerInputProps) => {
  let labelClassname = 'my-2 block text-[14px] font-[600]';
  if (type === 'blue') {
    labelClassname =
      'my-1.5 font-medium text-[10px] text-influentia-light-blue block';
  }
  const { t } = useTranslation('common');
  const [open, setOpen] = useState<boolean>(false);
  const datePicker = useRef(null);

  return (
    <>
      <div className={`${fullSpace && 'w-full'} ${label ? '' : `${height}`}`}>
        <div className="flex flex-row w-full">
          {label && (
            <label htmlFor={label} className={labelClassname}>
              {label}
            </label>
          )}
          <div
            data-tooltip-id={`tooltip-${label}`}
            data-tooltip-content={tooltipText}
            className={`flex items-center justify-center ml-2 ${tooltipText ? 'cursor-pointer' : 'hidden'}`}
          >
            <TooltipIcon className="text-influentia-light-blue cursor-pointer" />
            <Tooltip
              className="!bg-dark-blue !opacity-100 !text-white font-montserrat !p-1 !text-sm rounded-10 max-w-[300px] text-center z-50"
              noArrow
              id={`tooltip-${label}`}
              place="bottom"
            />
          </div>
        </div>
        <Controller
          control={control}
          rules={validation}
          name={name}
          render={({ field: { onChange: fieldOnChange, value, ...rest } }) => {
            const dateValue = useMemo(() => {
              if (!value) return null;
              return dayjs(value).toDate();
            }, [monthYearPicker, value]);

            return (
              <div className="relative">
                <ReactDatePicker
                  {...rest}
                  id={id}
                  isClearable={isClearable}
                  wrapperClassName="datePicker"
                  popperProps={{ strategy: 'fixed' }}
                  placeholderText={
                    placeholder || t('global.placeholderDateInput')
                  }
                  showMonthYearPicker={monthYearPicker}
                  selected={dateValue}
                  ref={datePicker}
                  minDate={limitDate}
                  onChange={(date) => {
                    if (date !== null) {
                      const formattedDate = dayjs(date?.getTime()).format(
                        'MM/DD/YYYY',
                      );
                      fieldOnChange(formattedDate);
                      if (onChange) {
                        onChange(formattedDate);
                      }
                    } else {
                      fieldOnChange(null);
                      if (onChange) {
                        onChange(null);
                      }
                    }
                    setOpen(false);
                  }}
                  open={open}
                  onInputClick={() => setOpen(true)}
                  onClickOutside={() => setOpen(false)}
                  locale="es"
                  autoComplete="off"
                  dateFormat={monthYearPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
                  enableTabLoop={false}
                  withPortal={withModal}
                  popperPlacement="top-end"
                  className={`${errors && 'border border-red-600'} px-3 block ${height} min-w-[165px] text-dark-blue w-full py-[8px] text-sm px-3 font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0 rounded-[4px] focus-visible:outline-none`}
                  customInput={<input autoComplete="off" />}
                />
                <div
                  className={`absolute right-1 ${height === 'h-[25px]' ? 'bottom-1' : 'bottom-3'} ${(!hasIcon || value) && 'hidden'}`}
                  onClick={() => setOpen(true)}
                >
                  <CalendarIcon className="text-influentia-black size-[18px]" />
                </div>
              </div>
            );
          }}
        />
        <div className="mt-1">{errors && <Error error={errors.message} />}</div>
      </div>
    </>
  );
};
