import { useState } from 'react';

import { ArrowExpandIcon, EditIcon, TrashIcon } from '../../assets';

const ExpandableListElement = ({
  title,
  deleteFunction,
  editFunction,
  SubrowComponent,
  subrowElements,
  noSubrowsText,
}: {
  title: string;
  deleteFunction?: () => void;
  editFunction?: () => void;
  SubrowComponent?: any;
  subrowElements?: any[];
  noSubrowsText: string;
}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-col gap-y-1">
      <div
        onClick={(e) => {
          setExpanded(!isExpanded);
        }}
        className="bg-dark-blue rounded-10 w-max md:w-auto cursor-pointer"
      >
        <div className="py-1 px-2.5 flex flex-row justify-between lg:gap-20  sm:min-w-[200px]">
          <div className="flex flex-row">
            <button
              className={`flex items-center justify-center ${isExpanded ? 'rotate-90' : ''}`}
              onClick={(e) => {
                setExpanded(!isExpanded);
              }}
            >
              <ArrowExpandIcon />
            </button>
            <span className="!rounded-[5px] !px-3 !py-1 !font-bold !text-white">
              {title}
            </span>
          </div>
          <div className="flex flex-row gap-x-2">
            {editFunction && (
              <button onClick={editFunction}>
                <EditIcon className="fill-current text-white hover:text-influentia-light-blue" />
              </button>
            )}
            {deleteFunction && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFunction();
                }}
              >
                <TrashIcon className="fill-current text-white hover:text-red-status" />
              </button>
            )}
          </div>
        </div>
      </div>
      {isExpanded &&
        subrowElements?.map((e) => (
          <div className="bg-dark-blue-popups rounded-10 w-max md:w-auto">
            <div className="py-1 px-2.5 !pl-8 flex flex-row justify-between lg:gap-20">
              <SubrowComponent {...e} />
            </div>
          </div>
        ))}
      {isExpanded && (!subrowElements || subrowElements?.length === 0) && (
        <div className="bg-dark-blue-popups rounded-10 w-max md:w-auto">
          <div className="py-2 text-sm px-2.5 text-center italic text-white ">
            {noSubrowsText}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableListElement;
