import React from 'react';
import Select, { components } from 'react-select';

import {
  BlogIcon,
  InstagramIcon,
  LinkedinIcon,
  SubstackIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../assets';

const PlatformDropdown = ({
  platformSelected = 'Youtube',
  disabled = false,
  onChange,
}: {
  disabled?: boolean;
  platformSelected?: string;
  onChange: (platform: string) => void;
}) => {
  const options = [
    {
      value: 'Youtube',
      label: 'Youtube',
      icon: <YoutubeIcon className="size-[14px]" />,
    },
    {
      value: 'Instagram',
      label: 'Instagram',
      icon: <InstagramIcon className="size-[14px]" />,
    },
    {
      value: 'TikTok',
      label: 'TikTok',
      icon: <TiktokIcon className="size-[14px]" />,
    },
    {
      value: 'Substack',
      label: 'Substack',
      icon: <SubstackIcon className="size-[14px]" />,
    },
    {
      value: 'Twitter',
      label: 'Twitter',
      icon: <TwitterIcon className="size-[14px]" />,
    },
    {
      value: 'Blog',
      label: 'Blog/Newsletter',
      icon: <BlogIcon className="size-[14px]" />,
    },
    {
      value: 'Telegram',
      label: 'Telegram',
      icon: <TelegramIcon className="size-[14px]" />,
    },
    {
      value: 'Linkedin',
      label: 'Linkedin',
      icon: <LinkedinIcon className="size-[14px]" />,
    },
  ];

  const customStyles = {
    control: (base: any) => ({
      ...base,
      borderRadius: '10px',
      backgroundColor: '#213A51',
      color: 'white',
      padding: '2.5px',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      width: '100%',
      minWidth: '220px',
    }),
    singleValue: (base: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      border: 'none',

      color: 'white',
    }),
    option: (base: any, props: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      color: 'white',
      background: props.isSelected ? '#4b5f71' : '#213A51',
      backgroundColor: '#213A51',
      ':hover': {
        backgroundColor: '#00E7FF',
      },
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: '#213A51',
      borderRadius: '10px',
      zIndex: 10,
    }),
  };

  const customSingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div>{props.data.icon}</div>
      <span className="ml-2">{props.data.label}</span>
    </components.SingleValue>
  );

  const customOption = (props: any) => (
    <components.Option {...props}>
      <div>{props.data.icon}</div>
      <span className="ml-2">{props.data.label}</span>
    </components.Option>
  );

  return (
    <div>
      <Select
        isDisabled={disabled}
        defaultValue={options.find(
          (option) => option.value === platformSelected,
        )}
        onChange={(selectedOption) => {
          if (selectedOption) {
            onChange(selectedOption.value);
          }
        }}
        options={options}
        styles={customStyles}
        components={{ SingleValue: customSingleValue, Option: customOption }}
        isSearchable={false}
      />
    </div>
  );
};

export default PlatformDropdown;
