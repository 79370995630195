import { gql } from '@apollo/client';

export const CREATE_BRAND = gql`
  mutation CreateBrand(
    $name: String!
    $cif: String!
    $address: String!
    $paymentTerms: String!
    $companyName: String!
  ) {
    createBrand(
      name: $name
      cif: $cif
      address: $address
      paymentTerms: $paymentTerms
      companyName: $companyName
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UpdateBrand(
    $brandId: String!
    $cif: String
    $address: String
    $paymentTerms: String
    $name: String
    $companyName: String
  ) {
    updateBrand(
      brandId: $brandId
      cif: $cif
      address: $address
      paymentTerms: $paymentTerms
      name: $name
      companyName: $companyName
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_BRAND_PROFILE_PICTURE = gql`
  mutation UpdateBrandProfilePicture($brandId: String!, $file: Upload!) {
    updateBrandProfilePicture(brandId: $brandId, file: $file) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation DeleteBrand($brandId: String!) {
    deleteBrand(brandId: $brandId) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_BRAND_GROUP = gql`
  mutation CreateBrandGroup(
    $brand_id: String!
    $name: String
    $users: [String]
  ) {
    createBrandGroup(brand_id: $brand_id, name: $name, users: $users) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_BRAND_GROUP = gql`
  mutation UpdateBrandGroup($id: String!, $name: String, $users: [String]) {
    updateBrandGroup(id: $id, name: $name, users: $users) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_BRAND_GROUP = gql`
  mutation DeleteBrandGroup($id: String!) {
    deleteBrandGroup(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_BRAND_GROUP_USER = gql`
  mutation DeleteBrandGroupUser($group_id: String!, $user_id: String!) {
    deleteBrandGroupUser(group_id: $group_id, user_id: $user_id) {
      status
      code
      data
      error
    }
  }
`;
