import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query Brands(
    $name: String
    $offset: Int
    $limit: Int
    $fromCampaignActives: Int
    $toCampaignActives: Int
    $orderBy: String
    $orderDirection: String
  ) {
    brands(
      name: $name
      offset: $offset
      fromCampaignActives: $fromCampaignActives
      toCampaignActives: $toCampaignActives
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        name
        cif
        address
        payment_terms
        profile_picture
        createdAt
        updatedAt
        campaignCount
      }
    }
  }
`;

export const GET_BRAND = gql`
  query Brand($brandId: String!) {
    brand(brandId: $brandId) {
      status
      code
      error
      data {
        id
        name
        cif
        address
        companyName
        paymentTerms
        profilePicture
        users {
          id
          name
          blocked
          last_login
          email
          title
          profilePicture
        }
        groups {
          id
          name
          users
        }
      }
    }
  }
`;

export const GET_BRAND_GROUPS = gql`
  query BrandGroups($brand_id: String!) {
    brandGroups(brand_id: $brand_id) {
      status
      code
      data {
        id
        name
      }
      error
    }
  }
`;
