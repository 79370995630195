import { gql } from '@apollo/client';

export const GET_ALL_INVOICES = gql`
  query Invoices(
    $userId: String
    $campaignId: String
    $brandId: String
    $brand: Boolean
    $fromMoney: Float
    $toMoney: Float
    $fromPayment: String
    $toPayment: String
    $fromBilling: String
    $toBilling: String
    $name: String
    $offset: Int
    $limit: Int
    $orderBy: String
    $orderDirection: String
  ) {
    invoices(
      userId: $userId
      campaignId: $campaignId
      brandId: $brandId
      brand: $brand
      fromMoney: $fromMoney
      toMoney: $toMoney
      fromPayment: $fromPayment
      toPayment: $toPayment
      fromBilling: $fromBilling
      toBilling: $toBilling
      name: $name
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        brandName
        userName
        amount
        amountType
        status
        billingDate
        paymentDate
        campaignName
        file {
          id
          name
          url
        }
        brandProfilePicture
        userProfilePicture
      }
    }
  }
`;

export const USER_INVOICES = gql`
  query InvoicesUser($campaignId: String!) {
    invoicesUser(campaignId: $campaignId) {
      status
      code
      error
      data {
        user {
          id
          name
        }
        invoices {
          id
          amount
          status
          billingDate
          amountType
          paymentDate
          file {
            id
            name
            url
          }
        }
      }
    }
  }
`;
