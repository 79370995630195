import { gql } from '@apollo/client';

export const GET_DASHBOARD_INFO = gql`
  query DashboardInfo {
    dashboardInfo {
      status
      code
      error
      data {
        dates {
          date
          campaign_name
          user_name
          user_id
          campaign_id
        }
        campaigns {
          id
          name
          status
          price
          users {
            id
            profile_picture
          }
          dates {
            type
            id
            date
          }
          updatedRecently
          brand {
            id
            name
            profile_picture
          }
        }
      }
    }
  }
`;

export const GET_DASHBOARD_INFO_PENDING = gql`
  query DashboardInfoPending {
    dashboardInfoPending {
      status
      code
      error
      data {
        id
        name
        status
        price
        users {
          id
          profile_picture
        }
        dates {
          type
          id
          date
        }
        updatedRecently
        brand {
          id
          name
          profile_picture
        }
      }
    }
  }
`;

export const GET_CAMPAIGNS_LIST = gql`
  query Campaigns(
    $campaignName: String
    $status: [String]
    $minPrice: Float
    $maxPrice: Float
    $platforms: [String]
    $influencers: [influencerFilter]
    $client: [influencerFilter]
    $offset: Int
    $limit: Int
    $orderBy: String
    $orderDirection: String
  ) {
    campaigns(
      campaignName: $campaignName
      status: $status
      minPrice: $minPrice
      maxPrice: $maxPrice
      client: $client
      influencers: $influencers
      platforms: $platforms
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        name
        status
        price
        platforms
        publicationDate
        users {
          user_id
          name
          profile_picture
          status
          price
          platforms
          pieces
          publicationDate
          variable_type
        }
        brand {
          id
          name
          profile_picture
        }
      }
    }
  }
`;
export const GET_CAMPAIGNS_RECURRENT_LIST = gql`
  query CampaignsRecurrent(
    $campaignName: String
    $status: [String]
    $minPrice: Float
    $maxPrice: Float
    $platforms: [String]
    $influencers: [influencerFilter]
    $client: [influencerFilter]
    $offset: Int
    $limit: Int
    $orderBy: String
    $orderDirection: String
  ) {
    campaignsRecurrent(
      campaignName: $campaignName
      status: $status
      minPrice: $minPrice
      maxPrice: $maxPrice
      client: $client
      influencers: $influencers
      platforms: $platforms
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        name
        status
        price
        platforms
        publicationDate
        users {
          user_id
          name
          profile_picture
          status
          price
          platforms
          pieces
          publicationDate
          variable_type
        }
        brand {
          id
          name
          profile_picture
        }
      }
    }
  }
`;

export const GET_SINGLE_CAMPAIGN_MAIN_INFO = gql`
  query Campaign($campaignId: String!) {
    campaign(campaignId: $campaignId) {
      status
      code
      error
      data {
        name
        month
        brand_id
        brand_name
        brand_profile_picture
        money
        currency
        status
        platforms
        influencers {
          id
          name
          profile_picture
          status
          amount
          currency
          variable_type
          variable_amount
          actions {
            id
            type
            platform
            variable_type
            variable_amount
            variable_currency
          }
          pieces {
            id
            type
            status
            platform
            link
            hasScript
            hasPreview
            paused
            publishingAt
          }
        }
        notes {
          id
          message
          createdAt
          user {
            id
            profile_picture
            name
          }
        }
        stoppedNotifications {
          id
          createdAt
          user_id
        }
      }
    }
  }
`;
export const GET_SINGLE_CAMPAIGN_RECURRENT_MAIN_INFO = gql`
  query CampaignRecurrent($campaignId: String!) {
    campaignRecurrent(campaignId: $campaignId) {
      status
      code
      error
      data {
        name
        brand_id
        brand_name
        paused
        brand_profile_picture
        money
        status
        platforms
        influencers {
          id
          name
          profile_picture
          status
        }
      }
    }
  }
`;
export const SINGLE_CAMPAIGN = gql`
  query SingleCampaign(
    $campaignId: String!
    $influencerId: String
    $type: String
  ) {
    singleCampaign(
      campaignId: $campaignId
      influencerId: $influencerId
      type: $type
    ) {
      status
      code
      error
      data {
        name
        campaignMoney
        campaignInfluencerAmount
        campaignInfluencerCurrency
        variableType
        variableAmount
        profilePicture
        description
        allPlatforms
        status
        campaignStatus
        platforms
        publicationDate
        briefing
        disclaimers {
          id
          platform
          disclaimer
        }
        platformsLinks {
          name
          url
        }
        influencers {
          id
          name
        }
        brand {
          name
          profilePicture
        }
        notes {
          id
          message
          createdAt
          user {
            id
            profile_picture
            name
          }
        }
        actions {
          id
          type
          platform
          status
          publishingAt
          variable_type
          variable_amount
          variable_currency
        }
        links {
          id
          type
          referral
          platforms
          description
        }
      }
    }
  }
`;

export const SINGLE_CAMPAIGN_RECURRENT = gql`
  query SingleCampaignRecurrent($campaignId: String!, $influencerId: String!) {
    singleCampaignRecurrent(
      campaignId: $campaignId
      influencerId: $influencerId
    ) {
      status
      code
      error
      data {
        name
        campaignMoney
        campaignInfluencerAmount
        profilePicture
        description
        allPlatforms
        paused
        status
        campaignStatus
        platforms
        publicationDate
        briefing
        initDate
        finishDate
        disclaimers {
          id
          platform
          disclaimer
        }
        platformsLinks {
          name
          url
        }
        influencers {
          id
          name
        }
        brand {
          name
          profilePicture
        }
        actions {
          id
          type
          platform
        }
        links {
          id
          type
          referral
          platforms
          description
        }
      }
    }
  }
`;

export const GET_INFLUENCER_CAMPAIGN_GENERAL = gql`
  query InfluencerCampaignGeneral(
    $campaignId: String!
    $influencerId: String!
    $type: String
  ) {
    influencerCampaignGeneral(
      campaignId: $campaignId
      influencerId: $influencerId
      type: $type
    ) {
      status
      code
      data {
        name
        briefing
        platforms
        amount
        currency
        status
        profilePicture
        publicationDate
        referrals {
          id
          type
          referral
          platforms
          description
        }
        disclaimers {
          id
          platform
          disclaimer
        }
        descriptions {
          id
          description
          platform
        }
        notes {
          id
          message
          createdAt
          user {
            id
            profile_picture
            name
          }
        }
        pieces {
          id
          type
          status
          paused
          link
          hasScript
          hasPreview
          platform
          publishingAt
          description
          content {
            id
            type
            status
            files {
              id
              url
              version
            }
            link
          }
          timeline {
            id
            type
            description
            automatic
            createdAt
            user {
              user_id
              profile_picture
              name
            }
          }
        }
        dates {
          id
          type
          date
        }
      }
      error
    }
  }
`;
export const GET_DATES_BY_CAMPAIGN = gql`
  query GetDates(
    $campaignId: String
    $userId: String
    $month: String
    $year: String
  ) {
    getDates(
      campaignId: $campaignId
      userId: $userId
      year: $year
      month: $month
    ) {
      status
      code
      error
      data {
        dates {
          id
          type
          date
          actionType
          actionPlatform
          status
          user {
            id
            name
            profile_picture
          }
          campaign {
            id
            name
            from_date
            to_date
          }
        }
        campaign {
          id
          name
          from_date
          to_date
        }
      }
    }
  }
`;

export const GET_INFLUENCER_SETTINGS = gql`
  query InfluencerCampaignSettings(
    $influencerId: String!
    $campaignId: String!
  ) {
    influencerCampaignSettings(
      influencerId: $influencerId
      campaignId: $campaignId
    ) {
      status
      code
      error
      data {
        total_amount
        influencer_amount
        total_amountType
        influencer_amountType
        total_variable_amount
        influencer_variable_type
        influencer_variable_amount
        status
        referrals {
          id
          type
          referral
          platforms
          description
        }
        actions {
          id
          type
          platform
          description
          hasScript
          hasPreview
          number_of_action
          variable_type
          total_amount
          total_currency
          influencer_amount
          influencer_currency
          internal_validation
        }
      }
    }
  }
`;
export const GET_INFLUENCER_SETTINGS_RECURRENT = gql`
  query InfluencerCampaignRecurrentSettings(
    $influencerId: String!
    $campaignId: String!
  ) {
    influencerCampaignRecurrentSettings(
      influencerId: $influencerId
      campaignId: $campaignId
    ) {
      status
      code
      error
      data {
        total_amount
        influencer_amount
        total_amountType
        influencer_amountType
        total_variable_amount
        influencer_variable_type
        influencer_variable_amount
        cicle
        initDate
        finishDate
        status
        referrals {
          id
          type
          referral
          platforms
          description
        }
        actions {
          id
          type
          month
          platform
          description
          hasScript
          hasPreview
          number_of_action
          variable_type
          total_amount
          total_currency
          influencer_amount
          influencer_currency
          internal_validation
        }
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query CampaignSettings($campaignId: String!) {
    campaignSettings(campaignId: $campaignId) {
      status
      code
      error
      data {
        id
        name
        month
        money
        currency
        brand {
          id
          name
        }
        group_id
        briefing {
          id
          name
          url
        }
        influencers {
          id
          name
          profile_picture
          has_variable
        }
        disclaimers {
          id
          platform
          disclaimer
        }
        descriptions {
          id
          description
          platform
        }
        accountManagers {
          id
          name
          profile_picture
        }
      }
    }
  }
`;

export const GET_SETTING_RECURRENT = gql`
  query CampaignRecurrentSettings($campaignId: String!) {
    campaignRecurrentSettings(campaignId: $campaignId) {
      status
      code
      error
      data {
        id
        name
        currency
        group_id
        brand {
          id
          name
        }
        briefing {
          id
          name
          url
        }
        influencers {
          id
          name
          profile_picture
        }
        accountManagers {
          id
          name
          profile_picture
        }
        disclaimers {
          id
          platform
          disclaimer
        }
        descriptions {
          id
          description
          platform
        }
      }
    }
  }
`;

export const GET_RESOURCES = gql`
  query Resources($campaignId: String!, $recurrent: Boolean) {
    resources(campaignId: $campaignId, recurrent: $recurrent) {
      status
      code
      count
      error
      data {
        id
        campaign_id
        camapaign_recurrent_id
        user_id
        file_id
        link
        linkName
        createdAt
        updatedAt
        File {
          id
          url
          name
        }
      }
    }
  }
`;

export const GET_PLATFORMS_INFO = gql`
  query GetPlatformInfo($campaignId: String!) {
    getPlatformInfo(campaignId: $campaignId) {
      status
      code
      error
      data {
        platforms {
          platforms
          descriptions
          disclaimers
          referrals {
            referral
            type
            description
            user {
              id
              name
            }
          }
        }
        briefing {
          id
          name
          url
        }
      }
    }
  }
`;
export const GET_PLATFORMS_RECURRENT_INFO = gql`
  query GetPlatformRecurrentInfo($campaignId: String!) {
    getPlatformRecurrentInfo(campaignId: $campaignId) {
      status
      code
      error
      data {
        platforms {
          platforms
          descriptions
          disclaimers
          referrals {
            referral
            type
            description
            user {
              id
              name
            }
          }
        }
        briefing {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_NOTES = gql`
  query Notes($campaignId: String!, $influencerId: String, $type: String!) {
    notes(campaignId: $campaignId, influencerId: $influencerId, type: $type) {
      status
      code
      error
      data {
        id
        message
        createdAt
        user {
          id
          profile_picture
          name
        }
      }
    }
  }
`;
