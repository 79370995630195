import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_BRAND_GROUPS } from '../../graphql/brands/query';
import type { ResponseGetBrandGroup } from '../../types';
import { Error } from '../common/Error';
import { GenericDropdown } from './GenericDropdown';

type BrandGroupDropdownProps = {
  control: any;
  validation?: any;
  name: string;
  label?: string;
  placeholder?: string;
  multi?: boolean;
  searchable?: boolean;
  storeFullOption?: boolean;
  hasAddButton?: boolean;
  error?: string;
  isClearable?: boolean;
  brand_id?: string;
};

export const BrandGroupDropdown = ({
  control,
  name,
  label,
  validation,
  placeholder,
  multi = false,
  searchable = false,
  storeFullOption = false,
  hasAddButton = false,
  error,
  isClearable = true,
  brand_id,
}: BrandGroupDropdownProps) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>();
  const { t } = useTranslation('common');

  const { data, loading: loadingGroups } = useQuery<ResponseGetBrandGroup>(
    GET_BRAND_GROUPS,
    {
      variables: { brand_id },
      skip: !brand_id,
    },
  );

  useEffect(() => {
    if (data) {
      setOptions(
        data.brandGroups.data?.map((o) => ({
          label:
            o.name === 'All Users' ? t('singleCampaign.allUsersGroup') : o.name,
          value: o.id,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (!brand_id) setOptions([]);
  }, [brand_id]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-end gap-1">
        <GenericDropdown
          isClearable={isClearable}
          control={control}
          name={name}
          storeFullOption={storeFullOption}
          label={label}
          isSearchable={searchable}
          options={options || []}
          isMulti={multi}
          validation={validation}
          isLoading={loadingGroups}
          placeholder={placeholder || label || ''}
        />
        {/* <div
          className={`w-[46px] h-[41px] bg-influentia-light-blue flex items-center justify-center rounded-[4px] cursor-pointer ${hasAddButton ? '' : 'hidden'}`}
          onClick={() => setOpen(true)}
        >
          <AddClientIcon />
        </div>
        {open && (
          <ModalPortal>
            {type === 'brand' && (
              <BrandPopUp
                setOpen={setOpen}
                redirect={false}
                refetch={() => getBrandsDB()}
                setCreatedBrand={setCreatedUser}
              />
            )}
            {type === 'influencer' && (
              <InfluencerPopUp
                setOpen={setOpen}
                redirect={false}
                refetch={() => getInfluencersDB()}
                setCreatedInfluencer={setCreatedUser}
              />
            )}
          </ModalPortal>
        )} */}
      </div>
      {error && <Error error={error} />}
    </div>
  );
};
